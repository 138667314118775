import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import FormControl from "@mui/material/FormControl";
import Translate from "../../../config/Translate";
import {
  GET_PETS_URL,
  GET_PROFILE_URL,
  UPDATE_PROFILE_URL,
} from "../../../helper/Url";
import { AXIOS_GET, AXIOS_PUT } from "../../../config/axios";
import { useDispatch, useSelector } from "react-redux";
import { UserInfo, selectSelectedClinic } from "../../../features/getUserSlice";
import {
  ProfileAvatar,
  ProfileFname,
  ProfileLname,
  selectUpdatePassword,
  UpdateUserPassword,
} from "../../../features/ShowAccountSlice";
import PageLoader from "../../../helper/PageLoader";
import FirstTimePopUp from "../FirstTimePopUp";
import "./rightstyle.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NewPassword from "../NewPassword";
import { selectLanguage } from "../../../features/LanguageSlice";
import En from "../../../helper/Dictionary/En";
import Fr from "../../../helper/Dictionary/Fr";
import { useForm } from "react-hook-form";
import Fade from "react-reveal/Fade";
import AnimalPopUp from "../AnimalPopUp";

const RightProfileForm = () => {
  const token = JSON.parse(localStorage.getItem("token"))?.access_token;
  const selectedClinic = useSelector(selectSelectedClinic);
  let hostname = window.location.hostname
    .split(".")
    .slice()
    .slice(-2)
    .join(".");
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const showUpdateForm = useSelector(selectUpdatePassword);
  const [ShowPop, SetPop] = useState(false);
  const [ShowAnimalPop, SetAnimalPop] = useState(false);
  const [isanimalavailable, setisanimalavailable] = useState(false);
  const languageValue = useSelector(selectLanguage);
  const pinfo = useRef();
  const paddress = useRef();
  const pcontact = useRef();
  const precommended = useRef();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, dirtyFields, touchedFields },
    trigger
  } = useForm({
    mode: 'onBlur',  // Enable validation on blur
    reValidateMode: 'onChange'  // Re-validate on change after blur
  });
  const pFirstName =
    languageValue === "En"
      ? En["page.signUpFirstNamePlaceholder"]
      : Fr["page.signUpFirstNamePlaceholder"];
  const pTitle = languageValue === "En" ? "Title" : "Title";
  const pLastName =
    languageValue === "En"
      ? En["page.signUpLastNamePlaceholder"]
      : Fr["page.signUpLastNamePlaceholder"];
  const pAddress =
    languageValue === "En" ? En["page.AddressInput"] : Fr["page.AddressInput"];
  const pCity =
    languageValue === "En" ? En["page.CityInput"] : Fr["page.CityInput"];
  const pPhone =
    languageValue === "En" ? En["page.PhoneInput"] : Fr["page.PhoneInput"];
  const pCode =
    languageValue === "En"
      ? En["page.PostalCodeInput"]
      : Fr["page.PostalCodeInput"];
  const pCountry = languageValue === "En" ? "Country" : "Pays";

  // Add error summary state
  const [showErrorSummary, setShowErrorSummary] = useState(false);

  // Helper to get section validation status
  const getSectionValidation = (fields) => {
    return fields.some(field => errors[field]);
  };

  const changeActiveBtn = (e, refname) => {
    let btns = document.querySelectorAll(".btnLink");
    btns.forEach((btn) => {
      btn.classList.remove("activebtn");
      if (btn == e.target) {
        btn.classList.add("activebtn");
      }
    });
    refname.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };
  const getAllPets = (count = 0) => {
    AXIOS_GET(GET_PETS_URL, token)
      .then((res) => {
        res.data?.data[0]?.isempty === true
          ? setisanimalavailable(false)
          : setisanimalavailable(true);
      })
      .catch((e) => {
        if (count < 3) {
          const delay = Math.pow(2, count) * 1000;
          setTimeout(() => getAllPets(count + 1), delay);
        }
        else{
          toast.error(languageValue === "En" ? 'Failed to fetch pets. Please try again later.' : 'Échec de la récupération des animaux. Veuillez réessayer plus tard.');
        }
      });
  };
  const getUserdata = (retryCount = 0) => {
    setShowLoader(true);
    let defaultValues = {};
    AXIOS_GET(GET_PROFILE_URL, token)
      .then((response) => {
        setShowLoader(false);
        let user_info = response.data.data;
        dispatch(UserInfo(user_info));
        dispatch(ProfileAvatar(response.data.data.feature_image));
        dispatch(ProfileFname(response.data.data.first_name));
        dispatch(ProfileLname(response.data.data.last_name));
        defaultValues.first_name = user_info.first_name;
        defaultValues.last_name = user_info.last_name;
        defaultValues.title = user_info.title;
        defaultValues.country = user_info.country;
        defaultValues.email = user_info.email;
        defaultValues.address = user_info.address;
        defaultValues.contact_with_email = user_info.contact_with_email;
        defaultValues.contact_with_sms = user_info.contact_with_sms;
        defaultValues.contact_with_whatsapp = user_info.contact_with_whatsapp;
        defaultValues.city = user_info.city;
        defaultValues.phone = user_info.phone;
        defaultValues.postal_code = user_info.postal_code;
        defaultValues.referant_description = user_info.referant_description;
        reset({ ...defaultValues });
        setShowLoader(false);
        user_info.address === "" ||
        user_info.phone === "" ||
        user_info.city ===  ""
          ? SetPop(true)
          : SetAnimalPop(true);
        
          if(user_info.login_count > 1){
            SetAnimalPop(false);
          }
      })
      .catch((e) => {
        if (retryCount < 3) {
          // Retry up to 3 times with exponential backoff
          const delay = Math.pow(2, retryCount) * 1000;
          setTimeout(() => getUserdata(retryCount + 1), delay);
        } else {
          setShowLoader(false);
          languageValue === "En"
            ? toast("Failed to fetch profile data. Please try again later.")
            : toast("Échec de la récupération des données du profil. Veuillez réessayer plus tard.");
        }
      });
  };
  useEffect(() => {
    getUserdata();
    getAllPets();
  }, []);
  const handleFormUpdate = (data) => {
    if (Object.keys(errors).length > 0) {
      setShowErrorSummary(true);
      // Scroll to first error
      const firstErrorField = Object.keys(errors)[0];
      const element = document.getElementsByName(firstErrorField)[0];
      element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      
      // Show error toast
      toast.error(
        languageValue === "En" 
          ? "Please correct the errors in the form before saving"
          : "Veuillez corriger les erreurs dans le formulaire avant de sauvegarder"
      );
      return;
    }
    
    setShowErrorSummary(false);
    data.config_id = selectedClinic;
    setShowLoader(true);
    AXIOS_PUT(
      UPDATE_PROFILE_URL,
      data,
      token,
      languageValue == "En" ? "en" : "fr"
    )
      .then((result) => {
        languageValue === "En"
          ? toast("User Updated Successful!")
          : toast("Mise à jour de l'utilisateur réussie!");
        getUserdata();
        dispatch(UserInfo(result.data.data));
        setShowLoader(false);
      })
      .catch((e) => {
        languageValue === "En"
          ? toast("Something went wrong ")
          : toast("Quelque chose s'est mal passé");
        setShowLoader(false);
      })
  };
  return (
    <RightContainer>
      {ShowPop ? <FirstTimePopUp /> : ""}
      {ShowAnimalPop ? <AnimalPopUp isAvailable={isanimalavailable} /> : ""}
      <ToastContainer />
      {showErrorSummary && Object.keys(errors).length > 0 && (
        <ErrorSummary>
          <h4>
            <Translate Word={languageValue === "En" ? "Please correct the following errors:" : "Veuillez corriger les erreurs suivantes:"} />
          </h4>
          <ul>
            {Object.entries(errors).map(([field, error]) => (
              <li 
                key={field}
                onClick={() => {
                  const element = document.getElementsByName(field)[0];
                  element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }}
              >
                <Translate Word={error.message} />
              </li>
            ))}
          </ul>
        </ErrorSummary>
      )}
      <Form onSubmit={handleSubmit(handleFormUpdate)}>
        <div className="LinksHeader">
          <button
            type="button"
            className="activebtn btnLink"
            onClick={(e) => changeActiveBtn(e, pinfo)}
          >
            <Translate Word="page.profile.pinfo" />
          </button>
          <button
            type="button"
            className="btnLink"
            onClick={(e) => changeActiveBtn(e, paddress)}
          >
            {" "}
            <Translate Word="page.AddressInput" />
          </button>
          <button
            type="button"
            className="btnLink"
            onClick={(e) => changeActiveBtn(e, pcontact)}
          >
            <Translate Word="page.profile.contactwith" />
          </button>
          <button
            type="button"
            className="btnLink"
            onClick={(e) => changeActiveBtn(e, precommended)}
          >
            <Translate Word="page.referant.description" />
          </button>
        </div>
        <TitleHeader ref={pinfo}>
          <Translate Word="page.profile.pinfo" />
        </TitleHeader>
        <CatgCard data-catg="1">
          <div className="div">
            <FormGroup className="form-group">
              <div>
                <Label>
                  <Redspan>*</Redspan> <Translate Word="page.FirstNameInput" />{" "}
                </Label>
                <InputGroup
                  type="text"
                  placeholder={pFirstName}
                  {...register("first_name", { 
                    required: languageValue === "En" ? "First name is required" : "Le prénom est requis",
                    minLength: {
                      value: 2,
                      message: languageValue === "En" ? "First name must be at least 2 characters" : "Le prénom doit comporter au moins 2 caractères"
                    }
                  })}
                  className={errors.first_name ? 'error' : touchedFields.first_name ? 'valid' : ''}
                />
                {errors.first_name && (
                  <ValidationMessage>
                    <Translate Word={errors.first_name.message} />
                  </ValidationMessage>
                )}
              </div>
              <div>
                <Label>
                  <Redspan>*</Redspan> <Translate Word="page.LastNameInput" />
                </Label>
                <InputGroup
                  type="text"
                  placeholder={pLastName}
                  {...register("last_name", { 
                    required: languageValue === "En" ? "Last name is required" : "Le nom est requis",
                    minLength: {
                      value: 2,
                      message: languageValue === "En" ? "Last name must be at least 2 characters" : "Le nom doit comporter au moins 2 caractères"
                    }
                  })}
                  className={errors.last_name ? 'error' : touchedFields.last_name ? 'valid' : ''}
                />
                {errors.last_name && (
                  <ValidationMessage>
                    <Translate Word={errors.last_name.message} />
                  </ValidationMessage>
                )}
              </div>
            </FormGroup>

            <FormGroup className="form-group">
              <FormControl fullWidth>
                <Label>
                  <Redspan>*</Redspan> <Translate Word="page.title" />{" "}
                </Label>
                <select
                  className={`selectStyles ${errors.title ? 'error' : touchedFields.title ? 'valid' : ''}`}
                  {...register("title", { 
                    required: languageValue === "En" ? "Title is required" : "Le titre est requis"
                  })}
                >
                  <option value="">{pTitle}</option>
                  <option value={"Madame"}>
                    <Translate Word="page.general.madame"></Translate>
                  </option>
                  <option value={"Monsieur"}>
                    <Translate Word="page.general.monsieur"></Translate>
                  </option>
                  <option value={"Mademoiselle"}>
                    <Translate Word="page.general.mademoiselle"></Translate>
                  </option>
                </select>
                {errors.title && (
                  <ValidationMessage>
                    <Translate Word={errors.title.message} />
                  </ValidationMessage>
                )}
              </FormControl>
              <FormControl fullWidth>
                <Label>
                  <Redspan>*</Redspan> <Translate Word="page.country" />
                </Label>
                <select
                  className={`selectStyles ${errors.country ? 'error' : touchedFields.country ? 'valid' : ''}`}
                  {...register("country", { 
                    required: languageValue === "En" ? "Country is required" : "Le pays est requis"
                  })}
                >
                  <option value={"Suisse"}>
                    <Translate Word="page.general.suisse"></Translate>
                  </option>
                  <option value={"France"}>
                    <Translate Word="page.general.france"></Translate>
                  </option>
                  <option value={"Italie"}>
                    <Translate Word="page.general.Italie"></Translate>
                  </option>
                  <option value={"Allemagne"}>
                    <Translate Word="page.general.Allemagne"></Translate>
                  </option>
                </select>
                {errors.country && (
                  <ValidationMessage>
                    <Translate Word={errors.country.message} />
                  </ValidationMessage>
                )}
              </FormControl>
            </FormGroup>

            <Label>
              {" "}
              <Redspan>*</Redspan>
              <Translate Word="antTable.title.email" />
            </Label>
            <Input
              type="email"
              placeholder="Email"
              disabled
              {...register("email")}
            />
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
              <ChangePasswordButton type="button" onClick={() => dispatch(UpdateUserPassword())}>
                <Translate Word="page.profile.changePassword.btn" />
              </ChangePasswordButton>
            </div>
            {(isDirty && Object.keys(dirtyFields).some(field => ['first_name', 'last_name', 'title', 'country'].includes(field))) && (
              <div className="section-actions">
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                      type="submit"
                      className={Object.keys(errors).length > 0 ? 'has-errors' : ''}
                    >
                      <Translate Word="page.profile.saveChanges.btn" />
                      {Object.keys(errors).length > 0 && (
                        <span className="error-indicator">!</span>
                      )}
                    </Button>
                  </div>
                  {Object.keys(errors).length > 0 && (
                    <ButtonErrorSummary>
                      <ul>
                        {Object.entries(errors).map(([field, error]) => (
                          <li key={field} onClick={() => document.getElementsByName(field)[0]?.focus()}>
                            {error.message}
                          </li>
                        ))}
                      </ul>
                    </ButtonErrorSummary>
                  )}
                </div>
              </div>
            )}
          </div>
        </CatgCard>
        <TitleHeader ref={paddress}>
          <Translate Word="page.AddressInput" />
        </TitleHeader>
        <CatgCard data-catg="2">
          <div>
            <FormGroup className="form-group">
              <div>
                <Label>
                  <Redspan>*</Redspan> <Translate Word="page.AddressInput" />
                </Label>
                <InputGroup
                  type="text"
                  placeholder={pAddress}
                  {...register("address", { 
                    required: languageValue === "En" ? "Address is required" : "L'adresse est requise"
                  })}
                  className={errors.address ? 'error' : touchedFields.address ? 'valid' : ''}
                />
                {errors.address && (
                  <ValidationMessage>
                    <Translate Word={errors.address.message} />
                  </ValidationMessage>
                )}
              </div>
              <div>
                <Label>
                  <Redspan>*</Redspan> <Translate Word="page.CityInput" />
                </Label>
                <InputGroup
                  type="text"
                  placeholder={pCity}
                  {...register("city", { 
                    required: languageValue === "En" ? "City is required" : "La ville est requise"
                  })}
                  className={errors.city ? 'error' : touchedFields.city ? 'valid' : ''}
                />
                {errors.city && (
                  <ValidationMessage>
                    <Translate Word={errors.city.message} />
                  </ValidationMessage>
                )}
              </div>
            </FormGroup>
            <FormGroup className="form-group">
              <div>
                <Label>
                  {" "}
                  <Redspan>*</Redspan> <Translate Word="page.phoneInput.placeholder" />
                </Label>
                <InputGroup
                  type="text"
                  placeholder={pPhone}
                  {...register("phone", {
                    required: languageValue === "En" ? "Phone number is required" : "Le numéro de téléphone est requis",
                    pattern: {
                      value: /^\+41\d{9}$|^\+33\d{9}$/,
                      message: languageValue === "En" 
                        ? "Phone number must start with +41 or +33 followed by 9 digits" 
                        : "Le numéro de téléphone doit commencer par +41 ou +33 suivi de 9 chiffres"
                    }
                  })}
                  className={errors.phone ? 'error' : touchedFields.phone ? 'valid' : ''}
                  onBlur={() => trigger("phone")}
                />
                {errors?.phone?.type === "required" ? (
                  <Perror>
                    <Fade bottom>
                      <Translate Word="errors.form.phone.required"></Translate>
                    </Fade>
                  </Perror>
                ) : (
                  errors?.phone?.type === "pattern" ? (
                  <Perror>
                    <Fade bottom>
                      <Translate Word="errors.form.phone.invalid"></Translate>
                    </Fade>
                  </Perror>
                ) : (
                  ""
                )
                )}
              </div>
              <div>
                <Label>
                  {" "}
                  <Redspan>*</Redspan> <Translate Word="page.PostalCodeInput" />
                </Label>
                <InputGroup
                  type="text"
                  placeholder={pCode}
                  {...register("postal_code", { 
                    required: languageValue === "En" ? "Postal code is required" : "Le code postal est requis"
                  })}
                  className={errors.postal_code ? 'error' : touchedFields.postal_code ? 'valid' : ''}
                />
                {errors.postal_code && (
                  <ValidationMessage>
                    <Translate Word={errors.postal_code.message} />
                  </ValidationMessage>
                )}
              </div>
            </FormGroup>
            {(isDirty && Object.keys(dirtyFields).some(field => ['address', 'city', 'phone', 'postal_code'].includes(field))) && (
              <div className="section-actions">
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                      type="submit"
                      className={Object.keys(errors).length > 0 ? 'has-errors' : ''}
                    >
                      <Translate Word="page.profile.saveChanges.btn" />
                      {Object.keys(errors).length > 0 && (
                        <span className="error-indicator">!</span>
                      )}
                    </Button>
                  </div>
                  {Object.keys(errors).length > 0 && (
                    <ButtonErrorSummary>
                      <ul>
                        {Object.entries(errors).map(([field, error]) => (
                          <li key={field} onClick={() => document.getElementsByName(field)[0]?.focus()}>
                            {error.message}
                          </li>
                        ))}
                      </ul>
                    </ButtonErrorSummary>
                  )}
                </div>
              </div>
            )}
          </div>
        </CatgCard>
        <TitleHeader ref={pcontact}>
          <Translate Word="page.profile.contactwith" />
        </TitleHeader>
        <CatgCard data-catg="3">
          <div>
            <FormGroup>
              <FormControl fullWidth>
                <Label>
                  <Redspan>*</Redspan>{" "}
                  <Translate Word="page.contact_with_email" />
                </Label>
                <select
                  className={`selectStyles ${errors.contact_with_email ? 'error' : touchedFields.contact_with_email ? 'valid' : ''}`}
                  {...register("contact_with_email", { 
                    required: languageValue === "En" ? "Email contact preference is required" : "La préférence de contact par email est requise"
                  })}
                >
                  <option value={"yes"}>
                    <Translate Word="page.general.yes"></Translate>
                  </option>
                  <option value={"no"}>
                    <Translate Word="page.general.no"></Translate>
                  </option>
                </select>
                {errors.contact_with_email && (
                  <ValidationMessage>
                    <Translate Word={errors.contact_with_email.message} />
                  </ValidationMessage>
                )}
              </FormControl>
              <FormControl fullWidth>
                <Label>
                  <Redspan>*</Redspan>{" "}
                  <Translate Word="page.contact_with_sms" />
                </Label>
                <select
                  className={`selectStyles ${errors.contact_with_sms ? 'error' : touchedFields.contact_with_sms ? 'valid' : ''}`}
                  {...register("contact_with_sms", { 
                    required: languageValue === "En" ? "SMS contact preference is required" : "La préférence de contact par SMS est requise"
                  })}
                >
                  <option value={"yes"}>
                    <Translate Word="page.general.yes"></Translate>
                  </option>
                  <option value={"no"}>
                    <Translate Word="page.general.no"></Translate>
                  </option>
                </select>
                {errors.contact_with_sms && (
                  <ValidationMessage>
                    <Translate Word={errors.contact_with_sms.message} />
                  </ValidationMessage>
                )}
              </FormControl>
              {hostname != "evc.clinic" && (
                <FormControl fullWidth>
                  <Label>
                    <Redspan>*</Redspan>

                    <Translate Word="page.contact_with_whatsapp" />
                  </Label>
                  <select
                    className="selectStyles"
                    {...register("contact_with_whatsapp", { required: false })}
                  >
                    <option value={"yes"}>
                      <Translate Word="page.general.yes"></Translate>
                    </option>
                    <option value={"no"}>
                      <Translate Word="page.general.no"></Translate>
                    </option>
                  </select>
                  {errors?.contact_with_whatsapp?.type === "required" ? (
                    <Perror>
                      <Fade bottom>
                        <Translate Word="errors.form.secondName.required"></Translate>
                      </Fade>
                    </Perror>
                  ) : (
                    ""
                  )}
                </FormControl>
              )}
            </FormGroup>
            {(isDirty && Object.keys(dirtyFields).some(field => ['contact_with_email', 'contact_with_sms', 'contact_with_whatsapp'].includes(field))) && (
              <div className="section-actions">
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                      type="submit"
                      className={Object.keys(errors).length > 0 ? 'has-errors' : ''}
                    >
                      <Translate Word="page.profile.saveChanges.btn" />
                      {Object.keys(errors).length > 0 && (
                        <span className="error-indicator">!</span>
                      )}
                    </Button>
                  </div>
                  {Object.keys(errors).length > 0 && (
                    <ButtonErrorSummary>
                      <ul>
                        {Object.entries(errors).map(([field, error]) => (
                          <li key={field} onClick={() => document.getElementsByName(field)[0]?.focus()}>
                            {error.message}
                          </li>
                        ))}
                      </ul>
                    </ButtonErrorSummary>
                  )}
                </div>
              </div>
            )}
          </div>
        </CatgCard>
        <TitleHeader ref={precommended}>
          {" "}
          <Translate Word="page.referant.description" />
        </TitleHeader>
        <CatgCard data-catg="4">
          <div>
            <FormGroup>
              <FormControl fullWidth>
                <Label>
                  <Redspan>*</Redspan>{" "}
                  <Translate Word="page.referant.description" />
                </Label>
                <select
                  className={`selectStyles ${errors.referant_description ? 'error' : touchedFields.referant_description ? 'valid' : ''}`}
                  {...register("referant_description", { 
                    required: languageValue === "En" ? "Referral source is required" : "La source de référence est requise"
                  })}
                >
                  {hostname != "evc.clinic" && (
                    <>
                      <option value="" disabled selected>
                        <Translate Word="page.referant.select"></Translate>
                      </option>
                      <option value={"Recommandé par un ami"}>
                        <Translate Word="page.referant.byfriend"></Translate>
                      </option>
                      <option value={"Google Adwords"}>
                        <Translate Word="page.referant.bygads"></Translate>
                      </option>
                      <option value={"Local / Search"}>
                        <Translate Word="page.referant.bylocal"></Translate>
                      </option>
                      <option value={"Vétérinaire traitant"}>
                        <Translate Word="page.referant.byvet1"></Translate>
                      </option>{" "}
                      <option value={"Facebook / Instagram"}>
                        <Translate Word="page.referant.byfbc"></Translate>
                      </option>
                      <option value={"Vétérinaire Fermé"}>
                        <Translate Word="page.referant.byvt2"></Translate>
                      </option>{" "}
                    </>
                  )}
                  {hostname == "evc.clinic" && (
                    <>
                      <option value="" disabled selected>
                        <Translate Word="page.referant.select"></Translate>
                      </option>
                      <option value="Google">
                        <Translate Word="page.referant.Google"></Translate>
                      </option>
                      <option value={"Facebook / Instagram"}>
                        <Translate Word="page.referant.byfbc"></Translate>
                      </option>
                      <option value={"Vétérinaire traitant"}>
                        <Translate Word="page.referant.byvet1"></Translate>
                      </option>
                      <option value={"Local.ch"}>
                        <Translate Word="page.referant.Localch"></Translate>
                      </option>
                      <option value={"Recommandé par un proche"}>
                        <Translate Word="page.referant.Relatives"></Translate>
                      </option>
                    </>
                  )}
                </select>
                {errors.referant_description && (
                  <ValidationMessage>
                    <Translate Word={errors.referant_description.message} />
                  </ValidationMessage>
                )}
              </FormControl>
              <FormControl fullWidth />
              <FormControl fullWidth />
            </FormGroup>
            {(isDirty && Object.keys(dirtyFields).some(field => ['referant_description'].includes(field))) && (
              <div className="section-actions">
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button 
                      type="submit"
                      className={Object.keys(errors).length > 0 ? 'has-errors' : ''}
                    >
                      <Translate Word="page.profile.saveChanges.btn" />
                      {Object.keys(errors).length > 0 && (
                        <span className="error-indicator">!</span>
                      )}
                    </Button>
                  </div>
                  {Object.keys(errors).length > 0 && (
                    <ButtonErrorSummary>
                      <ul>
                        {Object.entries(errors).map(([field, error]) => (
                          <li key={field} onClick={() => document.getElementsByName(field)[0]?.focus()}>
                            {error.message}
                          </li>
                        ))}
                      </ul>
                    </ButtonErrorSummary>
                  )}
                </div>
              </div>
            )}
          </div>
        </CatgCard>
      </Form>
      {showUpdateForm ? <NewPassword /> : ""}
      {showLoader ? <PageLoader /> : ""}
    </RightContainer>
  );
};

export default RightProfileForm;

const RightContainer = styled.div`
  flex: 2;
  padding: 20px;
  padding-top: 0px;
  position: relative;
  background: #f1f4f8 !important;
  @media screen and (max-width: 500px) {
    padding: 10px;
    padding-top: 0px;
  }
`;
const CatgCard = styled.div`
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(233, 233, 233);
  margin-bottom: 40px;
  padding: 24px;
  position: relative;
  
  .section-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #e9e9e9;
  }

  @media (max-width: 600px) {
    .section-actions {
      position: sticky;
      bottom: 0;
      background: white;
      padding: 15px 0;
      margin: 0 -24px -24px;
      padding: 15px 24px;
    }
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 4px 10px;
  font-size: 13px;
  border: 1px solid #e4e6e9;
  border-radius: 4px;
  height: 42px;
  padding: 6px 10px;
  line-height: 1.5;
  color: rgb(89, 89, 89);
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(233, 233, 233);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  margin-bottom: 14px;
  outline: none;
  &:focus {
    border-color: #40a9ff;
  }
  &[type="checkbox"] {
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
  &.error {
    border-color: #ff4d4f;
    
    &:focus {
      border-color: #ff4d4f;
      box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
    }
  }
  
  &.valid {
    border-color: #52c41a;
    
    &:focus {
      border-color: #52c41a;
      box-shadow: 0 0 0 2px rgba(82, 196, 26, 0.2);
    }
  }
`;
const InputGroup = styled(Input)``;
const Form = styled.form``;
const FormAction = styled.div`
  display: flex;
  justify-content: center;
`;
const Button = styled.button`
  padding: 6px 16px;
  height: 42px;
  cursor: pointer;
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
  font-weight: 500;
  border-radius: 4px;
  outline: none;
  border: none;
  &.has-errors {
    background: #ff4d4f;
    border-color: #ff4d4f;
  }

  .error-indicator {
    margin-left: 8px;
    font-weight: bold;
  }
`;
const FormGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 12px !important;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;
const Label = styled.label`
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  display: block;
  width: 100%;
`;
const LabelCheckBox = styled(Label)`
  display: flex;
  gap: 8px;
`;
const Pinstead = styled.div`
  text-align: center;
  padding-top: 20px;
  color: #76afc8;
  font-size: 14px;
  cursor: pointer;
`;
const Redspan = styled.span`
  color: red;
  margin-right: 8px;
`;
const Perror = styled.p`
  color: #d03333e0;
  font-size: 14px;
  margin-bottom: 14px;
`;

const TitleHeader = styled.h1`
  font-weight: 500;
  font-size: 24px;
  color: #1c2232;
  margin-bottom: 14px;
`;
const MoboActionButton = styled.div`
 display:none;
 @media (max-width: 600px) {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: fixed;
  bottom: 67px;
  width: 110%;
  background:#f1f4f8 !important;
  padding: 7px;
  margin-left: -40px;
  padding-left: 40px;
  z-index: 2;
 }
 
`

const ChangePasswordButton = styled.button`
  padding: 6px 16px;
  height: 42px;
  cursor: pointer;
  color: #fff;
  background: transparent;
  border-color: #1890ff;
  font-weight: 500;
  border-radius: 4px;
  outline: none;
  border: 2px solid #76afc8;
  color:#76afc8
`;

const ValidationMessage = styled.div`
  color: #d03333e0;
  font-size: 12px;
  margin-top: 4px;
`;

const ErrorSummary = styled.div`
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 4px;

  h4 {
    color: #d03333e0;
    margin-bottom: 8px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    color: #d03333e0;
    margin-bottom: 4px;
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

// Add new styled component for button error summary
const ButtonErrorSummary = styled.div`
  margin-top: 10px;
  padding: 8px 12px;
  background-color: #fff2f0;
  border-radius: 4px;
  font-size: 12px;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #ff4d4f;
  }

  li {
    margin-bottom: 4px;
    cursor: pointer;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

